import { createStore } from 'vuex'

export default createStore({
  state: {
    addminurl:'http://localhost:8000/',
    url:'http://localhost:8080/',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
