<template>
  <!-- main container of all the page elements -->
  <div id="wrapper">
    <!-- header of the page -->
    <header style="background:#080848" id="header">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <!-- main logo of the page -->
            <div class="logo logosection">
              <a href="#"><img style="width:100px;height:70px;" src="images/logo.png" alt="SNOWFLAKE"></a>
            </div>
            <!-- main logo of the page end -->
            <!-- nav of the page -->
            <nav class="navbar navbar-default">
              <div class="navbar-header">
                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                  <span class="sr-only">Toggle navigation</span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>
              </div>
              <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                <ul class="nav navbar-nav navbar-right">
                  <li><a class="smooth" href="#wrapper">হোম</a></li>
                  <li><a class="smooth" href="#about">পরিচিতি</a></li>
                  <li><a class="smooth" href="#service">প্যাকেজ</a></li>
                  <li><a class="smooth" href="#process">প্রক্রিয়া</a></li>
                  <li><a class="smooth" href="#portfolio">পোর্টফোলিও</a></li>
                  <li><a class="smooth" href="#REGISTRATION">রেজিস্ট্রেশন</a></li>
                  <li><a @click.prevent="loginpage()" class="smooth" to="/login" href="#">লগ ইন</a></li>
                  <li><a class="smooth" href="#contact">যোগাযোক</a></li>
                </ul>
              </div>
            </nav>
            <!-- nav of the page end -->
          </div>
        </div>
      </div>
    </header>
    <!-- header of the page end -->
    <!-- main of the page -->
    <main id="main" role="main">
      <!-- banner of the page -->
      <section class="banner">
        <!-- main slider of the page -->
        <ul class="list-unstyled main-slider">
          <li class="text-center" style="background-image: url('fontecss/images/classroomimages4.webp');">
            <div class="caption">
              <h1>এইচ.এস.সি আইসিটি বিষয়ে অনন্য কোচিং</h1>
              <span class="title">আমাদের কোচিং সেন্টারে এইচ.এস.সি আইসিটি বিষয়ের উপর বিশেষজ্ঞ শিক্ষকদের দ্বারা উন্নত মানের শিক্ষা প্রদান করা হয়।</span>
              <div class="btn-holder">
                <a href="#" class="btn">আরও জানুন</a>
              </div>
            </div>
          </li>
          <li class="text-center" style="background-image: url('fontecss/images/ciu-visitor-4.jpg');">
            <div class="caption">
              <h1>এম.সি.কিউ ও সি.কিউ প্রশ্নের বিশেষজ্ঞ সমাধান</h1>
              <span class="title">আমাদের কোচিংয়ে এমসিকিউ এবং সি.কিউ প্রশ্নের উপর বিশেষ মনোযোগ দিয়ে থাকি এবং ছাত্র-ছাত্রীদের সঠিক ও সহজ সমাধান শেখানো হয়।</span>
              <div class="btn-holder">
                <a href="#" class="btn">আরও জানুন</a>
              </div>
            </div>
          </li>
  <li class="text-center" style="background-image: url('fontecss/images/ciu-admission-test-4.jpg');">
            <div class="caption">
              <h1>প্রত্যেক ছাত্রের উন্নতির জন্য ব্যক্তিগত মনোযোগ</h1>
              <span class="title">আমাদের প্রতিটি ছাত্রের উন্নতির জন্য ব্যক্তিগত গাইডেন্স এবং মনোযোগ দেয়া হয়।</span>
              <div class="btn-holder">
                <a href="#" class="btn">আরও জানুন</a>
              </div>
            </div>
          </li>
            <li class="text-center" style="background-image: url('fontecss/images/IMG_0011.jpg');">
            <div class="caption">
              <h1>আধুনিক শিক্ষাদান পদ্ধতি ও প্রযুক্তির ব্যবহার</h1>
              <span class="title">আমরা আধুনিক শিক্ষাদান পদ্ধতি এবং প্রযুক্তির সাহায্যে ছাত্র-ছাত্রীদের শিক্ষাদান করে থাকি, যাতে তারা সবচেয়ে ভালো ফলাফল অর্জন করতে পারে।</span>
              <div class="btn-holder">
                <a href="#" class="btn">আরও জানুন</a>
              </div>
            </div>
          </li>
        </ul>
        <!-- main slider of the page end -->
        <a href="#about" class="smooth next-section"><i class="divider"></i></a>
      </section>
      <!-- banner of the page end -->
      <!-- about us of the page -->
      <section id="about" class="about-sec bg_teacher_section">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <div class="text-center">
                <img class="teacherimages " src="fontecss/img/teacherimages.jpg">
              </div>
            </div>

            <div class="col-xs-12 col-sm-6">
              <div>
                <h1 class="line_height">মোঃ কাজীউল ইসলাম</h1>
                <h4 class="font_size line_height">সহকারী অধ্যাপক ও বিভাগীয় প্রধান আই.সি.টি. বিভাগ<br>পুলিশ লাইন্স স্কুল এন্ড কলেজ, রংপুর <br> ২০ বছরের শিক্ষকতা , মাস্টার ট্রেইনার পরীক্ষক ও <br> প্রশ্ন মডারেটর দিনাজপুর শিক্ষা বোর্ড</h4>
                <p class="about_teacher_p">"প্রিয় ছাত্র-ছাত্রীবৃন্দ,

আপনারা কি আইসিটির জগতে আপনার দক্ষতা উন্নত করতে চান? 'গাণিতিক আইসিটি' আপনাদের জন্য এনেছে এক অনন্য সুবর্ণ সুযোগ। আইসিটি হলো এমন একটি বিষয় যা বর্তমান সময়ে অত্যন্ত গুরুত্বপূর্ণ এবং আবশ্যক। অসংখ্য ছাত্র-ছাত্রী এই বিষয়ে টেনশনে ভুগছেন কারণ আধুনিক শিক্ষা পদ্ধতিতে নতুন নতুন ধারণা এবং প্রযুক্তির অন্তর্ভুক্তি ঘটছে, যেমন: প্রোগ্রামিং, কোডিং, ডেটাবেজ ম্যানেজমেন্ট, সংখ্যা পদ্ধতি ইত্যাদি। অনেক শিক্ষার্থী এসব নতুন বিষয়ে বই পড়েও পূর্ণাঙ্গ ধারণা পাচ্ছেন না কারণ তারা এই বিষয়গুলিতে সম্পূর্ণ নতুন।

আমাদের 'গাণিতিক আইসিটি' সেন্টারে, আমরা এমসিকিউ এবং সিকিউ প্রশ্নাবলীর সমাধানের পাশাপাশি আইসিটির উন্নত এবং আধুনিক ধারণা প্রদান করি। এইচ.এস.সি পরীক্ষার্থীদের জন্য আমাদের বিশেষ গাইডলাইন ও প্রস্তুতি ম্যাটেরিয়াল অত্যন্ত সাহায্যকারী
</p>
                <button type="submit" class="btn btn-success">যোগাযোগ </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- about us of the page end -->
      <!-- Project Rating of the page -->
      <section class="project-rating" style="background-image: url('fontecss/images/img04.jpg');">
        <div class="container">
          <div class="row">
            <div class="col-xs-6 col-sm-3 text-center wow fadeInLeft" data-wow-delay="0.6s">
              <span class="counter">246</span>
              <span class="title">ছাত্র তালিকা</span>
            </div>
            <div class="col-xs-6 col-sm-3 text-center wow fadeInUp" data-wow-delay="0.6s">
              <span class="counter">250</span>
              <span class="title">MCQ   প্রশ্ন</span>
            </div>
            <div class="col-xs-6 col-sm-3 text-center wow fadeInUp" data-wow-delay="0.8s">
              <span class="counter">118</span>
              <span class="title">পিডিএফ ফাইল</span>
            </div>
            <div class="col-xs-6 col-sm-3 text-center wow fadeInRight" data-wow-delay="0.6s">
              <span class="counter">105</span>
              <span class="title">CQ   প্রশ্ন</span>
            </div>
          </div>
        </div>
      </section>
      <!-- Project Rating of the page end -->
      <!-- service of the page -->
      <section id="service" class="service-sec bg_teacher_section">
        <div class="container">
          <div class="row">
            <header class="col-xs-12 text-center main-header wow fadeInUp" data-wow-delay="0.6s">
              <h2>আমাদের প্যাকেজ</h2>
              <p class="sub_title_color">'গাণিতিক আইসিটি' কোচিং সেন্টারে আমরা তিনটি বিশেষ প্যাকেজ প্রস্তাব করছি, যা প্রতিটি ছাত্র-ছাত্রীর প্রয়োজন এবং লক্ষ্যের সাথে মিলে যায়। </p>
            </header>
          </div>
          <!-- service list of the page -->
          <div class="row">
            <div class="col-xs-12 col-sm-4">
              <div class="cardbox text-center">
                <div class="">
                  <h2 style="background: blue; padding: 20px 0px; color: #fff;">বেসিক প্যাকেজ:</h2>
                  <h1>৳ ১০০০ <span>/1.5 মাস</span></h1>
                  <button type="submit" class="btn btn-success button_order">ক্রয় করুন </button>
                </div>
                <div class="solutions">
                  <p class="bottom_card">প্রোগ্রামিং  ল্যাংগুয়েজ</p>
                  <p class="bottom_card">আলগোরিদমস  এন্ড  ফলোচার্টস </p>
                  <p class="bottom_card">সি প্রোগ্রামিং </p>
                  <p class="bottom_card">সি-কিউ  প্রশ্ন  এবং   সমাধান</p>
                  <p class="bottom_card">এম সি-কিউ  প্রশ্ন  এবং   সমাধান </p>
                  <p class="bottom_card">পিডিএফ  নোটস ও  ভিডিও  টিউটোরিয়াল </p>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4">
              <div class="cardbox text-center">
                <div class="">
                  <h2 style="background: blue; padding: 20px 0px; color: #fff;">অ্যাডভান্সড প্যাকেজ:</h2>
                  <h1>৳ ২০০০  <span>/2 মাস</span></h1>
                  <button type="submit" class="btn btn-success button_order">ক্রয় করুন </button>
                </div>
                <div class="solutions">
                  <p class="bottom_card">নম্বর  সিস্টেম </p>
                  <p class="bottom_card">বুলিয়ান  এলগোরিদম  এন্ড  লজিকাল  ডিভাইস </p>
                  <p class="bottom_card">ওয়েবপেজ  ডিজাইন   এন্ড  এইচটিএমএল  </p>
                  <p class="bottom_card">সি-কিউ  প্রশ্ন  এবং   সমাধান</p>
                  <p class="bottom_card">এম সি-কিউ  প্রশ্ন  এবং   সমাধান</p>
                  <p class="bottom_card">পিডিএফ  নোটস ও  ভিডিও  টিউটোরিয়াল</p>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-4">
              <div class="cardbox text-center">
                <div class="">
                  <h2 style="background: #090985; padding: 20px 0px; color: #fff;">প্রিমিয়াম প্যাকেজ:<span> 1+2=3</span></h2>
                  <h1>৳ ২৫০০  <span>/4 মাস</span></h1>
                  <button type="submit" class="btn btn-success button_order">ক্রয় করুন </button>
                </div>
                <div class="solutions">
                  <p class="bottom_card">নম্বর  সিস্টেম</p>
                  <p class="bottom_card">বুলিয়ান  এলগোরিদম  এন্ড  লজিকাল  ডিভাইস</p>
                  <p class="bottom_card">ওয়েবপেজ  ডিজাইন   এন্ড  এইচটিএমএল </p>
                  <p class="bottom_card">সি প্রোগ্রামিং  + প্রোগ্রামিং  ল্যাংগুয়েজ</p>
                  <p class="bottom_card">সি-কিউ ,এম সি-কিউ  প্রশ্ন  এবং   সমাধান</p>
                  <p class="bottom_card">পিডিএফ  নোটস ও  ভিডিও  টিউটোরিয়াল</p>
                </div>
              </div>
            </div>
          </div>
          <!-- service list of the page end -->
        </div>
      </section>
      <!-- service of the page end -->
      <!-- process of the page -->
      <section id="process" class="process-sec">
        <div class="bg-darkgrey holder text-center">
          <header class="main-header">
            <h2>প্রক্রিয়া</h2>
          </header>
          <!-- process slider of the page -->
          <ul class="list-unstyled process-slider">
            <li>
              <span class="icon-strategy icon-large"></span>
              <div class="caption text-center">
                <span class="icon-strategy"></span>
                <h3>প্রক্রিয়া  ১</h3>
                <p>প্রথমে আপনার প্রয়োজনীয় প্যাকেজটি  দেখে নিনি। প্রতিটি প্যাকেজে এর মূল্য এবং সময় কাল দেয়া  আছে ।  আপনি যে প্যাকেজটি চয়ন করবেন সেই প্যাকেজ  এর সময় কাল অনুযায়ী আপনি প্যাকেজের এর পিডিএফ  নোটস ,এম সি-কিউ ,এম সি-কিউ  প্রশ্ন ও  ভিডিও  টিউটোরিয়াল দেখতে ও ডাউনলোড করতে পারবেন। </p>
              </div>
            </li>
            <li>
              <span class="icon-imac icon-large"></span>
              <div class="caption text-center">
                <span class="icon-imac"></span>
                <h3>প্রক্রিয়া ২</h3>
                <p>রেজিস্ট্রেশন অংশে গিয়ে আপনি আপনার যাবতীয় তথ্য দিয়ে রেজিস্ট্রেশন ফর্ম পূরণ করুন। এর পর আপনি আপনার ইমেইল ও পাসওয়ার্ড দিয়ে আপনার স্টুডেন্ট  প্যানেলে  লগইন করতে পারবেন লগইন মেনু থেকে। </p>
              </div>
            </li>
            <li>
              <span class="icon-rocket icon-large"></span>
              <div class="caption text-center">
                <span class="icon-rocket"></span>
                <h3>প্রক্রিয়া  ৩</h3>
                <p>লগইন এর পর আপনি কোনো ফাইল এক্সেস করতে পারবেন না যতক্ষণ পর্যন্ত আপনি আপনার পেমেন্ট প্রদান না করবেন। আপনার পেমেন্ট প্রদান এর জন্য মেকপেমেন্ট অপসন এ গিয়ে পেমেন্ট করার পর আপনি আপনার প্রয়োজনীয় ফাইল গুলি এক্সেস করতে পারবেন ও অনলাইন প্লাটফর্ম এর সুবিধা ভোগ  করতে পারবেন।</p>
              </div>
            </li>
          </ul>
          <!-- process slider of the page end -->
        </div>
      </section>
      <!-- process of the page end -->
      <!-- portfolio of the page -->
      <section id="portfolio" class="portfolio-sec">
        <div class="container">
          <div class="row">
            <header class="col-xs-12 text-center main-header">
              <h2>আমাদের কাজসমূহ</h2>
              <p class="work_sub_title_font">আমাদের ক্লাসের পাঠদান করার কিছু সুন্দর মুহূর্ত ও   ভিডিও টিউটোরিয়াল এবং  পিডিএফ ফাইল দেখে নিন।  </p>
            </header>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <ul class="list-unstyled filter-list">
                <li class="active"><a href="#">ALL</a></li>
                <li><a href="#" data-filter=".web">Images</a></li>
                <li><a href="#" data-filter=".brand">Videos</a></li>
                <li><a href="#" data-filter=".print">PDF</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div id="portfolio-holder">
          <div class="col web wow fadeInLeft" data-wow-delay="0.6s">
          <iframe src="https://www.youtube.com/embed/YEYiAK9qmRo" title="প্রোগ্রামিং কী,কেন এবং কীভাবে? [নতুনদের জন্য] 🔥 What is programming in Bangla?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <div class="over">
              <h3><a href="https://www.youtube.com/embed/YEYiAK9qmRo">ক্লাসরুমের ছবি</a></h3>
              <span class="lang">শো আপ অন স্ক্রীন</span>
            </div>
          </div>
          <div class="col web wow fadeInUp" data-wow-delay="0.6s">
            <iframe src="https://www.youtube.com/embed/irqbmMNs2Bo" title="C Language Tutorial for Beginners (with Notes &amp; Practice Questions)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <div class="over">
              <h3><a href="">ভিডিও ছবি</a></h3>
              <span  class="lang">শো আপ অন স্ক্রীন</span>
            </div>
          </div>
          <div class="col web wow fadeInLeft" data-wow-delay="0.6s">
          <iframe src="https://www.youtube.com/embed/t0vo7LmHrD8" title="কিভাবে প্রোগ্রামিং শিখবেন - How to learn programming" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <div class="over">
              <h3><a href="https://www.youtube.com/embed/t0vo7LmHrD8">পিডিএফ ইমেজ</a></h3>
              <span class="lang">শো আপ অন স্ক্রীন</span>
            </div>
          </div>
            <div class="col web wow fadeInLeft" data-wow-delay="0.6s">
          <iframe src="https://www.youtube.com/embed/t0vo7LmHrD8" title="কিভাবে প্রোগ্রামিং শিখবেন - How to learn programming" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <div class="over">
              <h3><a href="https://www.youtube.com/embed/t0vo7LmHrD8">পিডিএফ ইমেজ</a></h3>
              <span class="lang">শো আপ অন স্ক্রীন</span>
            </div>
          </div>
            <div class="col print wow fadeInLeft" data-wow-delay="0.6s">
        <iframe src="https://www.youtube.com/embed/DNFor3HUgdQ" title="সংখ্যা পদ্ধতি ও ডিজিটাল ডিভাইস  ll অধ্যায়-৩ ll পর্ব-১ ll HSC ICT Chapter 3 Part 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <div class="over">
              <h3><a href="https://www.youtube.com/embed/DNFor3HUgdQ">পিডিএফ ইমেজ</a></h3>
              <span class="lang">শো আপ অন স্ক্রীন</span>
            </div>
          </div>
            <div class="col print wow fadeInLeft" data-wow-delay="0.6s">
          <iframe src="https://www.youtube.com/embed/YiTv5ODCAJs" title="ভার্চুয়াল রিয়েলিটি ১ম অধ্যায় ict class | hsc ict class 1st chapter virtual reality | hsc ict class |" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <div class="over">
              <h3><a href="https://www.youtube.com/embed/YiTv5ODCAJs">পিডিএফ ইমেজ</a></h3>
              <span class="lang">শো আপ অন স্ক্রীন</span>
            </div>
          </div>
          <div class="col ptint wow fadeInRight" data-wow-delay="0.6s">
          <iframe src="https://www.youtube.com/embed/YiTv5ODCAJs" title="ভার্চুয়াল রিয়েলিটি ১ম অধ্যায় ict class | hsc ict class 1st chapter virtual reality | hsc ict class |" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <div class="over">
              <h3><a href="#">ক্লাসরুমের ছবি</a></h3>
              <span class="lang">শো আপ অন স্ক্রীন</span>
            </div>
          </div>
        <div class="col web wow fadeInLeft" data-wow-delay="0.6s">
          <iframe src="https://www.youtube.com/embed/t0vo7LmHrD8" title="কিভাবে প্রোগ্রামিং শিখবেন - How to learn programming" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <div class="over">
              <h3><a href="https://www.youtube.com/embed/t0vo7LmHrD8">পিডিএফ ইমেজ</a></h3>
              <span class="lang">শো আপ অন স্ক্রীন</span>
            </div>
          </div>
          
        </div>

      </section>
      <!-- contact of the page -->
      <section id="REGISTRATION" class="contact-sec">
        <div class="container">
          <div class="row">
            <header class="col-xs-12 text-center main-header wow fadeInUp" data-wow-delay="0.6s">
              <h2> রেজিস্ট্রেশন  ফরম </h2>
            </header>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <img class="regestitionfaromimg" src="fontecss/regestitionfrom_img/istockphoto-1193039142-612x612.jpg">
            </div>
            <div class="col-xs-12 col-sm-6">
              <div class="contact wow fadeInRight" data-wow-delay="0.6s">
                <form  class="contact-form" id="contact-form">
                  <fieldset>
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Student First Name" >
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Student Last Name">
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Name of the institution" name="email">
                    </div>
                    <div class="form-group">
                      <select class="form-control">
                         <option>Select Class</option>
                         <option>Come From database class</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select class="form-control">
                         <option>Select Batch</option>
                         <option>Come From database Batch </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select class="form-control">
                         <option>Select Package</option>
                         <option>Come From database Package </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <input type="email" class="form-control" placeholder="Email" name="email">
                    </div>
                    <div class="form-group">
                      <input type="Phone" class="form-control" placeholder="Phone" name="">
                    </div>
                    <div class="form-group">
                      <input type="password" class="form-control" placeholder="password" name="email">
                    </div>
                    <div class="form-group">
                      <input type="password" class="form-control" placeholder="Confirm password" name="email">
                    </div>
                  
                    <div class="form-group">
                      <textarea placeholder="Address" name="comment"></textarea>
                    </div>
                    <button type="submit" class="sub-btn" id="form-submit">Submit</button>
                  </fieldset>
                </form>
                <div class="done-massage">
                  <div class="holder"><strong>Thank you!</strong> We have received your message. </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- contact of the page end -->


      <!-- contact of the page -->
      <section id="contact" class="contact-sec contect_bg">
        <div class="container">
          <div class="row">
            <header class="col-xs-12 text-center main-header wow fadeInUp" data-wow-delay="0.6s">
              <h2>আমাদের সাথে যোগাযোগ:</h2>
              <p class="contect_sub_title_font">আপনার যেকোনো প্রশ্ন বা পরামর্শের জন্য আমাদের সাথে যোগাযোগ করুন। আমরা সবসময় আপনার সেবায় উৎসাহিত ও প্রস্তুত।</p>
            </header>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-6">
              <div class="contact-info wow fadeInLeft" data-wow-delay="0.6s">
                <h5>মোঃ কাজীউল ইসলাম</h5>
                <ul class="list-unstyled address-list">
                  <li>
                    <address> কলেজ  রোড , রংপুর  সিটি , রংপুর  ডিভিশন , ৫৪০২। </address>
                  </li>
                  <li>
                    <i class="txt">বিকাশ</i>
                    <a href="tel:01790-111777">&nbsp;01790-111777</a>
                  </li>
                  <li>
                    <i class="txt">ফোন</i>
                    <a href="tel:01744808022">&nbsp;&nbsp;&nbsp;01744808022</a>
                  </li>
                  <li>
                    <i class="txt">ইমেইল</i>
                    <a href="mailto:kajiulislam@gmail.coms">kajiulislam@gmail.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6">
              <div class="contact wow fadeInRight" data-wow-delay="0.6s">
                <h5>যোগাযোগ ফর্ম</h5>
                <form action="https://htmlbeans.com/html/snowflake/inc/process.php" class="contact-form" id="contact-form">
                  <fieldset>
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="নাম" name="নাম">
                    </div>
                    <div class="form-group">
                      <input type="email" class="form-control" placeholder="ইমেইল" name="email">
                    </div>
                    <div class="form-group">
                      <textarea placeholder="মেসেজ" name="comment"></textarea>
                    </div>
                    <button type="submit" class="sub-btn" id="form-submit">পাঠান</button>
                  </fieldset>
                </form>
                <div class="done-massage">
                  <div class="holder"><strong>Thank you!</strong> We have received your message. </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- contact of the page end -->

    </main>
    <!-- main of the page end -->
    <!-- footer of the page -->
    <footer id="footer">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-8">
            <div class="copyright">
              <p>Copyright © 2016 <a href="#">GANITIK-ICT</a> by Softyfy</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4">
            <!-- Social network of the page -->
            <ul class="list-unstyled social-network">
              <li><a href="#"><i class="icon-facebook"></i></a></li>
              <li><a href="#"><i class="icon-twitter"></i></a></li>
              <li><a href="#"><i class="icon-linkedin"></i></a></li>
              <li><a href="#"><i class="icon-google-plus"></i></a></li>
            </ul>
            <!-- Social network of the page end -->
          </div>
        </div>
      </div>
    </footer>
    <!-- footer of the page end -->
    <!-- Back Top of the page -->
    <span id="back-top" class="fa fa-chevron-up"></span>
  </div>

</template>
<script>
export default {
  data(){
    return{

    }
  },
  computed:{
      url(){
        return this.$store.state.url;
      },
    },
    methods: {
    loginpage(){
      window.location.assign(this.url+"login")
    },
  },
};
</script>
<style scoped>
  @keyframes rotateYLeftRight {
      0%, 100% {
        transform: rotateY(0deg);
      }
      50% {
        transform: rotateY(180deg);
      }
    }
    .logosection a img{
     animation: rotateYLeftRight 3s infinite alternate ease-in-out;
   }

   .background-image-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Creates a semi-transparent black overlay */
  border-radius: 10px; /* Optional: for rounded corners */
}
</style>>
